import React from "react"
import { Link } from "gatsby"
import { ESPA, LOGO_GRAFID } from "./images"


const Footer = () => (
  <footer className="footer-2">
    <div className="container">
      <div className="row">

        <div className="col-md-3">
          <div className="footer-lower" style={{ marginTop: "1rem" }}>

            <h5 style={{ fontWeight: 900, margin: 0, marginBottom: 10 }}>Επικοινωνία</h5>
            <div style={{ marginBottom: 10 }}>
              <span style={{ display: "inline-block", marginRight: 10 }}>Τυπογραφίο:</span>
              <a
                href="mailto:info@tipografio.gr">
                info@typografio.gr
              </a>
            </div>
            <div>
              <span style={{ display: "inline-block", marginRight: 10 }}>ΙΕΧΜΗ:</span>
              <a
                href="mailto:nfo@iceht.forth.gr">
                info@iceht.forth.gr
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-2 col-xs-4">
          <Link to="/">
            <img {...LOGO_GRAFID}/>
          </Link>

        </div>
        <div className="col-md-7 text-right">


          <img style={{ marginTop: "3rem" }} {...ESPA}/>


          <img style={{
            marginTop: "3rem",
            position: "fixed",
            bottom: 0,
            maxWidth: 321,
            right: 0,
            // right: "50%",
            // transform: "translateX(50%)",
          }} {...ESPA}
          />


          <div style={{ marginTop: "2rem" }}>
            <a
              href="http://dytikiellada.gr/%CF%80%CF%81%CE%BF%CF%83%CE%BA%CE%BB%CE%AE%CF%83%CE%B5%CE%B9%CF%82-%CF%80%CF%81%CE%BF%CE%BA%CE%B7%CF%81%CF%8D%CE%BE%CE%B5%CE%B9%CF%82/%CF%80%CF%81%CE%BF%CF%83%CE%BA%CE%BB%CE%B7%CF%83%CE%B7-ris3-1/">
              Καρτέλα έργου
            </a>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <a
              href="http://dytikiellada.gr/%CF%80%CF%81%CE%BF%CF%83%CE%BA%CE%BB%CE%AE%CF%83%CE%B5%CE%B9%CF%82-%CF%80%CF%81%CE%BF%CE%BA%CE%B7%CF%81%CF%8D%CE%BE%CE%B5%CE%B9%CF%82/%CF%80%CF%81%CE%BF%CF%83%CE%BA%CE%BB%CE%B7%CF%83%CE%B7-ris3-1/">
              Προκήρυξη
            </a>
          </div>
          <div>
          </div>
        </div>
      </div>

    </div>
  </footer>
)

export default Footer