import React from "react"
import styles from "./hero.module.css"
import { LOGO_GRAFID_WHITE_BIG, TOP } from "./images"


const Hero = (props) => {
  const { children, small } = props

  return (
    <section className="hero-slider large-image">
      <ul className="slides">
        <li style={small ? { height: 300 } : {}}>
          <div className={"background-image-holder overlay " + styles.hero} style={{ opacity: 1 }}>
            <img {...TOP} className={styles.hero__image}/>
          </div>

          <div className="vertical-align">
            <div className={styles.hero__text}>
              <div className="text-center">
                <img className={styles.logo} {...LOGO_GRAFID_WHITE_BIG}/>
                {children ? children
                  :
                  <h4 className="text-white">
                    Εκτύπωση και παραγωγή κυκλωμάτων τεχνολογίας
                    ανταλλαγής δεδομένων από κοντινή απόσταση (NFC) με μελάνια γραφενίου για
                    έξυπνες συσκευασίες φαρμακευτικών σκευασμάτων
                  </h4>


                }
              </div>
            </div>
          </div>
        </li>

      </ul>
    </section>
  )
}

export default Hero