import React from 'react';

export const TOP =  {
  src: require('../../../static/assets/img/proj/top.jpg'),
  title: 'Hero section photo',
  alt: 'team members'
}
export const ESPA =  {
  src: require('../../../static/assets/img/proj/espa.jpg'),
  title: 'Espa Logo',
  alt: 'Espa Logo'
}

export const PERIFERIA =  {
  src: require('../../../static/assets/img/proj/periferia.png'),
  alt: 'Region of Western Greece Logo'
}

export const LOGO_IEXMH = {
  src: require('../../../static/assets/img/proj/iexmh_logo.jpg'),
  alt: 'Institute of Chemical Engineering Sciences Logo'
};

export const BUILD_IEXMH = {
  src: require('../../../static/assets/img/proj/iexmh.jpg'),
  alt: 'Institute of Chemical Engineering Sciences Building'
};

export const LOGO_TYPO = {
  src: require('../../../static/assets/img/proj/typo logo1.png'),
  alt: 'Panagiotopoulos Tipografia Logo'
};

export const BUILD_TYPO = {
  src: require('../../../static/assets/img/proj/build_typo_1.jpg'),
  alt: 'Panagiotopoulos Tipografia Building'
}

export const BUILD_TYPO_2 = {
  src: require('../../../static/assets/img/proj/build_typo_2.png'),
  alt: 'Panagiotopoulos Tipografia building'
};

export const LOGO_GRAFID_WHITE = {
  src: require('../../../static/assets/img/proj/logo_white.png'),
  alt: 'Grafid logo white'
}

export const LOGO_GRAFID = {
  src: require('../../../static/assets/img/proj/grafid_logo.png'),
  alt: 'Grafid logo default'
}

export const LOGO_GRAFID_WHITE_BIG = {
  src: require('../../../static/assets/img/proj/logo_big_white.png'),
  alt: 'Grafid logo white big'
};

export const LOGO_GRAFID_BLACK_BIG = {
  src: require('../../../static/assets/img/proj/grafid_logo_black_big.png'),
  alt: 'Gradid logo default big'
};

export const SHOW_1 = {
  src: require('../../../static/assets/img/proj/show/IMG_1175.jpg'),
  alt: 'Working station'
};

export const SHOW_2 = {
  src: require('../../../static/assets/img/proj/show/IMG_1179.jpg'),
  alt: 'Silk-screen printing demo'
};

export const SHOW_3 = {
  src: require('../../../static/assets/img/proj/show/IMG_1188.jpg'),
  alt: 'Testing conductance'
};

export const SHOW_4 = {
  src: require('../../../static/assets/img/proj/show/IMG_1193.jpg'),
  alt: 'Silk-screen printing demo 2'
};

export const SHOW_5 = {
  src: require('../../../static/assets/img/proj/show/IMG_1241.jpg'),
  alt: 'circuit printed on paper'
};

export const SHOW_6 = {
  src: require('../../../static/assets/img/proj/show/IMG_1254.jpg'),
  alt: 'Panagiotopoulos Tipografia building inside'
};

export const SHOW_7 = {
  src: require('../../../static/assets/img/proj/show/IMG_1187.jpg'),
  alt: 'working station'
};

export const GAZA_1 = {
  src: require('../../../static/assets/img/proj/gaza.jpg'),
  alt: 'Silk-screen printing demo 3'
};
